@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Keania+One&display=swap");

@font-face {
  font-family: "SF-Arabic";
  src: url(../assets/SF-Arabic-font.ttf) format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Manjari", sans-serif;
}

a {
  color: #84309c;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.fm-inter {
  font-family: "Inter";
}

.fm-Russo {
  font-family: "Russo One";
}

.fm-Manjari {
  font-family: "Manjari";
}

.fm-Keania {
  font-family: "Keania One";
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-20 {
  font-size: 20px;
}

.f-25 {
  font-size: 25px;
}

.f-35 {
  font-size: 35px;
}

.f-40 {
  font-size: 40px;
}

.f-64 {
  font-size: 64px;
}

.f-70 {
  font-size: 70px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-160 {
  margin-bottom: 160px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-50 {
  padding-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}
.mb-110 {
  margin-bottom: 110px;
}

.input-file {
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background: rgba(217, 217, 217, 0);
}

.box-shadow {
  border-radius: 5px;
  border: 3px solid #fff;
  background: linear-gradient(
    180deg,
    rgba(18, 183, 242, 0.25) 0%,
    rgba(19, 79, 171, 0.25) 100%
  );
  box-shadow: 0px 2px 20px 4px #12b7f2;
}

.tile {
  border-radius: 5px;
  border: 2px solid #12b7f2;
  background: linear-gradient(
    180deg,
    rgba(18, 183, 242, 0.25) 0%,
    rgba(19, 79, 171, 0.25) 100%
  );
  font-family: "Keania One";
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: none;
  --bs-btn-border-color: none;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
  background: linear-gradient(180deg, #12b7f2 0%, #134fab 100%);
  border: 5px solid transparent; /* Set border to transparent */
  border-image: linear-gradient(180deg, #12b7f2 0%, #134fab 100%) 1;
  border-image-slice: 1; /* Ensures the border-image covers the entire border */
}

.sidebarDropdown {
  overflow: hidden;
  border-radius: 5px;
  width: fit-content;
}
.sidebarDropdown.show {
  overflow: visible;
}
.sidebarDropdown .btn-primary {
  padding: 3px 10px;
}
.ps-rtl .ps-menuitem-root.ps-active::before {
  left: 98% !important;
}

.btn:hover {
  color: none;
  background-color: inherit;
  border-color: inherit;
}

.btn-outline-primary {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(18, 183, 242, 0);
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  border-image: url("../assets/outlineBtnBorder.png");
  border-image-slice: 5; /* Ensures the border-image covers the entire border */
  border-width: 5px;
}

.gradient-bg-image {
  background-image: linear-gradient(180deg, #12b7f2 0%, #134fab 100%);
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%279%27 viewBox=%270 0 12 9%27 fill=%27none%27%3e%3cpath d=%27M11 1.40002L6 7.40002L1 1.40002%27 stroke=%27white%27 stroke-width=%272%27 stroke-miterlimit=%2710%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3e%3c/svg%3e");
  display: block;
  width: auto;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  appearance: none;
  background: linear-gradient(
    180deg,
    rgba(18, 183, 242, 0.25) 0%,
    rgba(19, 79, 171, 0.25) 100%
  );
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.option-bg {
  background: rgba(18, 183, 242, 0.25);
  /* background: linear-gradient(180deg, #12B7F2 24%, #134FAB 124%); */
}

.borderRight {
  border-right: 3px solid #12b7f2;
  min-height: 70px;
}

/* .borderRight::after {
  content: "" !important;
  height: 70px !important;
  width: 3px !important;
  background: #12b7f2 !important;
} */

.borderRadius {
  border-radius: 5px;
}

.mobileSidebar {
  background: #04689f;
  transition: width 2s linear;
  /* left: 0px; */
}
.mobileSidebar.rtl {
  right: 0px !important;
}
.mobileSidebar.rtl .mobileSidebarHover {
  flex-direction: row-reverse;
}

.mobileNavbarbg {
  background: #0589d2;
}
.mobileNavbarbg .row {
  --bs-gutter-x: 0;
}

.gradient-bg {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(5, 86, 186, 0.3) 100%
  );
}

.mobileSidebarHover:hover {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(5, 86, 186, 0.3) 100%
  );
}

/* .mobileSidebarHover::hover:before {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(5, 86, 186, 0.3) 100%
  );
} */

body {
  color: #e7e8e8;
  background: radial-gradient(
    123.15% 29.52% at 122.6% 27.68%,
    #056189 0%,
    #000c2b 100%
  );
}

/* feature page css */

@media only screen and (max-width: 600px) {
  .f-64 {
    font-size: 40px !important;
  }
  .f-20 {
    font-size: 12px !important;
    line-height: 25px !important;
  }
  .borderRight {
    min-height: 100px !important;
  }
  .f-35 {
    font-size: 20px !important;
  }
}
